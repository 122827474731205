@if (categories().length > 0 && isFsvSecureAccess) {
  <div id="menu-dropdown">
    <div id="border-shadow"></div>
    <ul class="dropdown px-1">
      @if (sharedService.environmentIsMCOE()) {
        <li class="main_li">
          <div class="line"></div>
          <a
            (click)="navigateToBugName('Special Offers')"
            class="ga-clic-bug-navigationmenu"
            data-titlega="Special Offers"
          >
            Special Offers
          </a>
        </li>
      }
      <li class="main_li">
        <div class="line"></div>
        <a
          (click)="navigateToCategoryId()"
          data-titlega="Full Catalog"
          class="ga-clic-product-navigationmenu"
          >Full Catalog</a
        >
      </li>

      @for (
        l1Category of categories();
        track l1Category.id;
        let l1Index = $index
      ) {
        <li class="main_li">
          <div class="line"></div>
          <a
            [routerLink]="l1Category.link"
            [attr.data-titlega]="l1Category.name"
            class="ga-clic-product-navigationmenu"
          >
            {{ l1Category.name }}
          </a>

          @if (l1Category.children.length > 0) {
            <ul class="sub_menu">
              @for (
                l2Category of l1Category.children;
                track l2Category.id;
                let l2Index = $index
              ) {
                <li class="sub_li">
                  <div class="sub_menu_container">
                    <div class="link_container">
                      <a
                        [routerLink]="l2Category.link"
                        [attr.data-titlega]="l2Category.name"
                        class="ga-clic-product-navigationmenu"
                      >
                        {{ l2Category.name }}
                      </a>
                      <div class="line2"></div>
                    </div>

                    @if (l2Category.children.length > 0) {
                      <ul [ngClass]="{ left: l2Index > 2 }">
                        <li class="sub_li dynamic">
                          <div class="sub_menu_container">
                            <a class="dynamic"></a>
                          </div>
                        </li>

                        @for (
                          l3Category of l2Category.children;
                          track l3Category.id
                        ) {
                          <li class="sub_li">
                            <div class="sub_menu_container">
                              <div class="link_container">
                                <a
                                  [routerLink]="l3Category.link"
                                  [attr.data-titlega]="l3Category.name"
                                  class="ga-clic-product-navigationmenu"
                                >
                                  {{ l3Category.name }}
                                </a>
                                <div class="line2"></div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    }
                  </div>
                </li>
              }
              <div class="separator" [ngClass]="{ left: l1Index > 2 }"></div>
            </ul>
          }
        </li>
      }

      @if (sharedService.environmentIsFSV()) {
        <li class="main_li">
          <div class="line"></div>
          <a
            (click)="navigateToBugName('Header Cards')"
            class="ga-clic-bug-navigationmenu"
            data-titlega="Header Cards"
          >
            Header Cards
          </a>
        </li>
        <li class="main_li">
          <div class="line"></div>
          <a
            (click)="navigateToBugName('Accessories')"
            class="ga-clic-bug-navigationmenu"
            data-titlega="Accessories"
          >
            Accessories
          </a>
        </li>
      }

      <li class="main_li inventory">
        @if (sharedService.environmentIsMCOE()) {
          <a
            class="btn-4 ga-clic-homepage mcoe"
            (click)="
              this.clientFunctions[
                sharedService.getEnvironmentId()
              ].epopClicked()
            "
            data-titlega="Header - EPOP"
            >Click Here To Order Equipment</a
          >
        } @else {
          <a
            class="btn-4 ga-clic-homepage fsv"
            (click)="
              this.clientFunctions[
                sharedService.getEnvironmentId()
              ].epopClicked()
            "
            data-titlega="Header - EPOP"
            >EPOP</a
          >
        }
      </li>

      @if (sharedService.environmentIsFSV()) {
        <li class="main_li displayStyleSwitch">
          <a class="btn-4" (click)="switchDisplayStyle()">
            @if (isCardLayout) {
              <img
                src="assets/images/products/CardViewOn.png"
                class="ga-clic-homepage"
                data-titlega="Header - Display style - List view"
              />
            } @else {
              <img
                src="assets/images/products/ListViewOn.png"
                class="ga-clic-homepage"
                data-titlega="Header - Display style - Card view"
              />
            }
          </a>
        </li>
      }
    </ul>
  </div>
}
