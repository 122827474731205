<footer class="footer">
  <div class="container">
    @if (!isMobile && isFsvSecureAccess) {
      <div class="mt-5">
        <div class="row header mb-2">
          <div class="col">
            <div class="title">Catalog Site Map</div>
            <div class="links">
              @if (sharedService.environmentIsFSV()) {
                <div class="subtitle">
                  <button
                    class="btn ga-clic-product-footermenu ga-clic-hp-products"
                    (click)="navigateToCategoryId(-1)"
                    data-titlega="Full Catalog"
                    data-titlega2="Catalog Site Map - Full Catalog"
                  >
                    Full Catalog
                  </button>
                </div>
              }

              @for (l1Category of categories(); track l1Category.id) {
                <div class="subtitle">
                  <button
                    class="btn ga-clic-product-footermenu ga-clic-hp-products"
                    (click)="navigateToCategoryName(l1Category.name)"
                    [attr.data-titlega]="l1Category.name"
                    [attr.data-titlega2]="
                      'Catalog Site Map - ' + l1Category.name
                    "
                  >
                    {{ l1Category.name }}
                  </button>
                </div>
              }

              @if (sharedService.environmentIsFSV()) {
                <div class="subtitle">
                  <button
                    class="btn ga-clic-product-footermenu ga-clic-hp-products"
                    (click)="navigateToBugName('Header Cards')"
                    data-titlega="Header Cards"
                    data-titlega2="Catalog Site Map - Header Cards"
                  >
                    Header Cards
                  </button>
                </div>
                <div class="subtitle">
                  <button
                    class="btn ga-clic-product-footermenu ga-clic-hp-products"
                    (click)="navigateToBugName('Accessories')"
                    data-titlega="Accessories"
                    data-titlega2="Catalog Site Map - Accessories"
                  >
                    Accessories
                  </button>
                </div>
              }
            </div>
          </div>
          <div class="col">
            <div class="title">Resources</div>
            <div class="links">
              <div class="subtitle">
                <a
                  (click)="
                    this.clientFunctions[
                      sharedService.getEnvironmentId()
                    ].faqClicked()
                  "
                  class="ga-clic-homepage"
                  data-titlega="Resources - FAQ"
                  >{{
                    sharedService.environmentIsPBNA() ? "How to Order" : "FAQ"
                  }}</a
                >
              </div>

              @if (!sharedService.environmentIsPBNA()) {
                <div class="subtitle">
                  <a
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].usellClicked()
                    "
                    class="ga-clic-homepage"
                    data-titlega="Resources - uSell"
                    >uSell</a
                  >
                </div>
              }

              @if (sharedService.environmentIsMCOE()) {
                <div class="subtitle">
                  <a
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].temporaryOrderClicked()
                    "
                    class="ga-clic-homepage"
                    data-titlega="Resources - Temporary Ordering Guidelines"
                    >Temporary Ordering Guidelines</a
                  >
                </div>
              }

              <div class="subtitle">
                @if (sharedService.environmentIsFSV()) {
                  <a
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].epopClicked()
                    "
                    class="ga-clic-homepage"
                    data-titlega="Resources - ePOP User Guide"
                    >ePOP User Guide</a
                  >
                }
              </div>
              <div class="subtitle">
                @if (sharedService.environmentIsFSV()) {
                  <a
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].epopClicked()
                    "
                    class="ga-clic-homepage"
                    data-titlega="Resources - ePOP Bulk Order Form"
                    >ePOP Bulk Order Form</a
                  >
                }
              </div>
            </div>
          </div>
          <div class="col">
            <div class="title">Help</div>
            <div class="links">
              <div class="subtitle">
                @if (sharedService.environmentIsFSV()) {
                  <a
                    href="mailto:PFSCatalog@pepsico.com"
                    class="ga-clic-homepage"
                    data-titlega="Help - PFSCatalog@pepsico.com"
                    >PFSCatalog&#64;pepsico.com</a
                  >
                }

                @if (sharedService.environmentIsMCOE()) {
                  <a
                    href="mailto:FLNAMCOECatalog@pepsico.com"
                    class="ga-clic-homepage"
                    data-titlega="Help - FLNAMCOECatalog@pepsico.com"
                    >FLNAMCOECatalog&#64;pepsico.com</a
                  >
                }

                @if (sharedService.environmentIsPBNA()) {
                  <a
                    (click)="
                      this.clientFunctions[
                        sharedService.getEnvironmentId()
                      ].contactClicked()
                    "
                    class="ga-clic-homepage"
                    data-titlega="Help - FLNAMCOECatalog@pepsico.com"
                    >Contact</a
                  >
                }
              </div>
            </div>
          </div>
          <div class="col desc-logo">
            @if (!sharedService.environmentIsPBNA()) {
              <div class="description">
                <p>
                  This Merchandising Tool Was Developed
                  <br />
                  By The Merchandising Center of Excellence
                </p>
              </div>
            }
            <div class="logo">
              <img
                [src]="
                  sharedService.environmentIsPBNA()
                    ? 'assets/images/footer/ACTIVATR_logo.jpg'
                    : 'assets/images/footer/MCOE_Logo_Black.png'
                "
                [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
              />
              <!-- @if (!sharedService.environmentIsPBNA()) {
                <span>Merchandising Center of Excellence</span>
              } -->
            </div>
          </div>
        </div>
      </div>
    }

    @if (isMobile && isFsvSecureAccess) {
      <div class="mt-2">
        <div class="mobile w-100">
          <div class="row">
            <div class="col-12 desc-logo">
              <div class="description">
                <p>
                  This Merchandising Tool Was Developed
                  <br />
                  By The Merchandising Center of Excellence
                </p>
              </div>
              <div class="logo">
                <img src="assets/images/footer/logo.png" />
                <span>Merchandising Center of Excellence</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="footer-nav list-group">
                <li class="list-group-item chapter">
                  <a
                    data-toggle="collapse"
                    href="#footer-whats-new"
                    aria-expanded="false"
                    class="collapsed"
                  >
                    Catalog Site Map
                    <span></span>
                  </a>
                </li>
                <div class="collapse" id="footer-whats-new">
                  @if (sharedService.environmentIsFSV()) {
                    <li class="list-group-item header">
                      <button
                        class="btn ga-clic-product-footermenu ga-clic-hp-products"
                        (click)="navigateToCategoryId(-1)"
                        data-titlega="Full Catalog"
                        data-titlega2="Catalog Site Map - Full Catalog"
                      >
                        Full Catalog
                      </button>
                    </li>
                  }

                  @for (l1Category of categories(); track l1Category.id) {
                    <li class="list-group-item header">
                      <button
                        class="btn ga-clic-product-footermenu ga-clic-hp-products"
                        (click)="navigateToCategoryName(l1Category.name)"
                        [attr.data-titlega]="l1Category.name"
                        [attr.data-titlega2]="
                          'Catalog Site Map - ' + l1Category.name
                        "
                      >
                        {{ l1Category.name }}
                      </button>
                    </li>
                  }

                  @if (sharedService.environmentIsFSV()) {
                    <li class="list-group-item header">
                      <button
                        class="btn ga-clic-product-footermenu ga-clic-hp-products"
                        (click)="navigateToBugName('Header Cards')"
                        data-titlega="Header Cards"
                        data-titlega2="Catalog Site Map - Header Cards"
                      >
                        Header Cards
                      </button>
                    </li>
                    <li class="list-group-item header">
                      <button
                        class="btn ga-clic-product-footermenu ga-clic-hp-products"
                        (click)="navigateToBugName('Accessories')"
                        data-titlega="Accessories"
                        data-titlega2="Catalog Site Map - Accessories"
                      >
                        Accessories
                      </button>
                    </li>
                  }
                </div>
              </ul>
            </div>
            <div class="col-12">
              <ul class="footer-nav list-group">
                <li class="list-group-item chapter">
                  <a
                    data-toggle="collapse"
                    href="#footer-resources"
                    aria-expanded="false"
                    class="collapsed"
                  >
                    Resources
                    <span></span>
                  </a>
                </li>
                <div class="collapse" id="footer-resources">
                  @if (!sharedService.environmentIsPBNA()) {
                    <li class="list-group-item header">
                      <a
                        (click)="navigateToPdfName('faq')"
                        class="ga-clic-homepage"
                        data-titlega="Resources - FAQ"
                        >FAQ</a
                      >
                    </li>
                  }

                  <li class="list-group-item header">
                    <a
                      (click)="
                        this.clientFunctions[
                          sharedService.getEnvironmentId()
                        ].usellClicked()
                      "
                      class="ga-clic-homepage"
                      data-titlega="Resources - uSell"
                      >uSell</a
                    >
                  </li>

                  @if (sharedService.environmentIsMCOE()) {
                    <li class="list-group-item header">
                      <a
                        (click)="
                          this.clientFunctions[
                            sharedService.getEnvironmentId()
                          ].temporaryOrderClicked()
                        "
                        class="ga-clic-homepage"
                        data-titlega="Resources - Temporary Ordering Guidelines"
                        >Temporary Ordering Guidelines</a
                      >
                    </li>
                  }

                  @if (sharedService.environmentIsFSV()) {
                    <li class="list-group-item header">
                      <a
                        (click)="
                          this.clientFunctions[
                            sharedService.getEnvironmentId()
                          ].epopClicked()
                        "
                        class="ga-clic-homepage"
                        data-titlega="Resources - ePOP User Guide"
                        >ePOP User Guide</a
                      >
                    </li>
                  }

                  <li class="list-group-item header">
                    @if (sharedService.environmentIsFSV()) {
                      <a
                        (click)="
                          this.clientFunctions[
                            sharedService.getEnvironmentId()
                          ].epopClicked()
                        "
                        class="ga-clic-homepage"
                        data-titlega="Resources - ePOP Bulk Order Form"
                        >ePOP Bulk Order Form</a
                      >
                    }
                  </li>
                </div>
              </ul>
            </div>
            <div class="col-12">
              <ul class="footer-nav list-group">
                <li class="list-group-item chapter">
                  <a
                    data-toggle="collapse"
                    href="#footer-help"
                    aria-expanded="false"
                    class="collapsed"
                  >
                    Help
                    <span></span>
                  </a>
                </li>
                <div class="collapse" id="footer-help">
                  <li class="list-group-item header">
                    @if (sharedService.environmentIsFSV()) {
                      <a
                        href="mailto:PFSCatalog@pepsico.com"
                        class="ga-clic-homepage"
                        data-titlega="Help -
                                                PFSCatalog@pepsico.com"
                        >PFSCatalog&#64;pepsico.com</a
                      >
                    }

                    @if (sharedService.environmentIsMCOE()) {
                      <a
                        href="mailto:FLNAMCOECatalog@pepsico.com"
                        class="ga-clic-homepage"
                        data-titlega="Help -
                                                FLNAMCOECatalog@pepsico.com"
                        >FLNAMCOECatalog&#64;pepsico.com</a
                      >
                    }
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    }
    <div
      class="row mt-5 copyright ml-0 mr-0"
      [ngClass]="{ fsv: sharedService.environmentIsFSV() }"
    >
      <div class="col">
        <div class="row top">
          <div class="col links">
            <span>Design by Gimbal Cube</span>
          </div>
        </div>
        <div class="row separator">
          <div class="col">
            <div class="line"></div>
          </div>
        </div>
        <div class="row bottom">
          <div class="col">
            <span class="text"
              >All trademarks are owned by
              {{
                sharedService.environmentIsPBNA()
                  ? "Pepsi Cola Company"
                  : "Frito-Lay North America"
              }}, Inc. (c) {{ getFooterYear() }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
